import { Messages, resolveResponseToProviderResult } from "@vodacom/web-common";

import { Environment } from "@vodacom/web-env";

import { CacheStrategy, State } from "@vodacom/web-state";

import { getTxnid } from "./rest-service-helper";

export class RestServiceProvider {
  static state = State.with("RestServiceProvider");



  static async getProductCatalog() {
    try {
      const catalogUrl = `${Environment.current.apiPath}/catalogPackage`;

      const res = await RestServiceProvider.state.fetch(catalogUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authorization: "Bearer " + result.token,
          // 'x-csrf-token':csrf.trim()
        },
        body: {
          "transaction-id": getTxnid(),
        },
        strategy: CacheStrategy.None,
      });

      const ret = resolveResponseToProviderResult(res, Messages.DefaultError);

      ret.status = res.status;

      return ret;
    } catch (e) {
      return {
        ok: false,
        error: e,
        trace: null,
        date: new Date(),
        result: null,
        code: null,
      };
    }
  }

  static async continue(msisdnParamater, data) {
    try {
      const msisdn = msisdnParamater;

      const CPLCheckServiceUrl = `${Environment.current.apiPath}/CPLCheckService`;

      const res = await RestServiceProvider.state.fetch(CPLCheckServiceUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + data.headerData.token,
          // 'x-csrf-token':data.headerData.csrftoken
        },
        body: {
          "transaction-id": getTxnid(),
          "client-txn-id": data.productCatalog.mslDetails["client-txn-id"],
          msisdn: msisdn,
          "custom-consent-msg":
            data.productCatalog.mslDetails["custom-consent-msg"],
        },
        strategy: CacheStrategy.None,
      });

      const ret = resolveResponseToProviderResult(res, Messages.DefaultError);
      ret.status = res.status;

      return ret;
    } catch (e) {
      return {
        ok: false,
        error: e,
        trace: null,
        date: new Date(),
        result: null,
        code: null,
      };
    }
  }

  static async cancelJourney(data, msisdn) {
    try {
      const getCancelServiceUrl = `${Environment.current.apiPath}/getCancelService`;

      const res = await RestServiceProvider.state.fetch(getCancelServiceUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + data.headerData.token,
          //'x-csrf-token':data.headerData.csrftoken
        },
        body: {
          "transaction-id": getTxnid(),
          msisdn: data.currentMsisdn ? data.currentMsisdn : msisdn,
          "client-txn-id": data.productCatalog.mslDetails["client-txn-id"],
          "service-name": data.productCatalog.mslDetails["service-name"],
          "partner-id": data.productCatalog.mslDetails["partner-id"],
        },
        strategy: CacheStrategy.None,
      });

      const ret = resolveResponseToProviderResult(res, Messages.DefaultError);
      ret.status = res.status;

      return ret;
    } catch (e) {
      return {
        ok: false,
        error: e,
        trace: null,
        date: new Date(),
        result: null,
        code: null,
      };
    }
  }
}
