import React, { useEffect, useState } from "react";
import { AppUtilsProvider, RestServiceProvider } from "providers";
import { DEFAULT_TECHNICAL_ERROR_MESSAGE, DEFAULT_TECHNICAL_ERROR_TITLE } from "utils/constants";
import ErrorIcon from "images/error.svg";
import { MainPage } from "ui/mainpage";
function App() {
  const [appData, setAppData] = useState({
    currentMsisdn: "",
    iMsisdn: "",
    successTextUrl: {},
    declineTextUrl: {},
    headerData: {},
    bannerFlag: false,
    productCatalog: null,
    contentOverrideMessage: "",
    defaultError: {
      display: false,
      title: "",
      messages: "",
      primaryButtonText: "",
      primaryButtonClickHandler: null,
      iconUrl: null,
    },
    spinnerFlag: false,
    redirectPartnerUrl: false,
  });

  const updateAppData = (newData) => {
    setAppData((prevData) => ({ ...prevData, ...newData }));
  };
  
  useEffect(() => {
    const getInitialData = async () => {
      updateAppData({ spinnerFlag: true });

     

      const productCatalog = await RestServiceProvider.getProductCatalog();

      if (!productCatalog.ok || !productCatalog.result?.result?.success) {
     
        updateAppData({
          defaultError: {
            display: true,
            title: DEFAULT_TECHNICAL_ERROR_TITLE,
            messages: DEFAULT_TECHNICAL_ERROR_MESSAGE,
            primaryButtonText: "Close",
            primaryButtonClickHandler: AppUtilsProvider.handleCloseATB,
            iconUrl: ErrorIcon,
          },
          spinnerFlag: false,
        });
        return;
      }

      const catalogResult = productCatalog.result.result;

      updateAppData({
        productCatalog: catalogResult,
        declineTextUrl: {
          Text: catalogResult.mslDetails.declinedmsg,
          Url: catalogResult.mslDetails["partner-redirect-url"],
        },
        headerData: {
          token: catalogResult.token,
          "client-txn-id": catalogResult["client-txn-id"],
        },
        bannerFlag: true,
        redirectPartnerUrl: catalogResult.mslDetails["partner-redirect-url"],
        spinnerFlag: false,
        defaultError: { display: false }, // Ensure this is reset after success
      });

      // Inject external script dynamically
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.innerHTML = catalogResult["EvScript"]["s"];
      document.head.appendChild(script);
      document.dispatchEvent(new Event("DCBProtectRun"));
    };

    getInitialData();
  }, []);

  return (
    <MainPage
      data={appData}
      actions={{
        updateDefaultError: (error) => updateAppData({ defaultError: error }),
        updateProductCatalog: (catalog) => updateAppData({ productCatalog: catalog }),
        displayBanner: (flag) => updateAppData({ bannerFlag: flag }),
        displaySpinner: (flag) => updateAppData({ spinnerFlag: flag }),
        updateMsisdn: (msisdn) => updateAppData({ currentMsisdn: msisdn }),
        updateIMsisdn: (msisdn) => updateAppData({ iMsisdn: msisdn }),
        updateBanner: (flag) => updateAppData({ bannerFlag: flag }),
        updateSuccessTextUrl: (url) => updateAppData({ successTextUrl: url }),
        updateDeclineTextUrl: (url) => updateAppData({ declineTextUrl: url }),
        updateContentOverrideMessage: (message) => updateAppData({ contentOverrideMessage: message }),
      }}
    />
  );
}

export default App;
