// Texts

export const DEFAULT_TECHNICAL_ERROR_MESSAGE =
  "We’re unable to process your subscription.Please try again later.";

export const DEFAULT_TECHNICAL_ERROR_TITLE = "Service not available right now";

export const DEFAULT_WAITING_MESSAGE =
  "Please accept the approval request sent to the number entered on the previous screen. Once accepted, you will be redirected to the next screen.";
export const DEFAULT_SECOND_WAITING_MESSAGE = "Do not close this window.";
export const DEFAULT_WAITING_MESSAGE_TITLE = "Just a sec...";

export const SPEND_LIMIT_TITLE = "Limit Reached";
export const SPEND_LIMIT_MESSAGE = "You have reached your content spend limit.";
export const SPEND_LIMIT_SECOND_MESSAGE =
  "Please contact Customer Care on 082 135 for assistance to adjust your spend limit.";

export const INSUFFICIENT_FUNDS_TITLE = "Insufficient Funds";
export const INSUFFICIENT_FUNDS_MESSAGE =
  "You don’t have enough airtime to purchase this service. Please recharge and try again.";
export const INSUFFICIENT_FUNDS_SECOND_MESSAGE = "";

export const TIMEOUT_TITLE = "Time out";
export const TIMEOUT_MESSAGE = "Your session has timed out.";
export const TIMEOUT_SECOND_MESSAGE = "Please try again.";

export const CONTENT_BLOCK_TITLE = "Content Block";

export const NUMBER_LOCKED_TITLE = "Number Locked";
export const NUMBER_LOCKED_MESSAGE = "This number has been locked.";
export const NUMBER_LOCKED_SECOND_MESSAGE =
  "Contact customer care on 082135 to check your content profile.";

export const NETWORK_LOCKED_TITLE = "Out Of Network";
export const NETWORK_LOCKED_MESSAGE =
  "This service is only available to Vodacom customers.";

export const DEFAULT_NO_MANDATES_MESSAGE =
  "DebiCheck Mandates \n\n You do not have any existing DebiCheck Mandates.";
export const DEFAULT_NO_MSISDN_MESSAGE =
  "No DebiCheck numbers \n Because you're not the main account holder you don't have any mandates to show.";
export const DEFAULT_TRIGGER_MANDATE_SUCCESS_MESSAGE =
  "Vodacom will perfom DebiCheck for your protection.\nDebicheck allows you to protect unauthorized debit orders.";
export const TOOLTIP_TEXT =
  "The MAH is the person responsible for paying the monthly bill. Their first Vodacom cellphone number is usually the main number associated with their account, and they can view all linked numbers that they pay for.";
export const MAH_TOOLTIP_NOTE_SUCCESS =
  "You can only link numbers that are main account holders (MAH).";
export const MAH_TOOLTIP_NOTE_ERROR =
  "You can only view mandates for linked numbers that are main account holders (MAH).";

//Configs

export const POPUP_TYPES = {
  ERROR_POPUP: "ERROR",
  SUCCESS_POPUP: "SUCCESS",
};

export const DEFAULT_POPUP_DATA = {
  type: POPUP_TYPES.ERROR_POPUP,
  bodyText: [],
  heading: [],
  primaryText: "Okay",
  onCloseClickHandler: () => {},
  onPrimaryClickHandler: () => {},
};

export const SORT_ORDER = {
  ascending: "ASC",
  descending: "DESC",
};

export const SPINNER_SIZE = "70";

export const TOOLTIP_POSITION = "top";

export const TOOLTIP_ICON_URL =
  "https://static.vodacomdigitalassets.co.za/common/images/icons/tooltip.svg";

//possible values on mandate status card
export const STATUS_DISPLAY_TEXTS = {
  PENDING: "PENDING...",
  SUSPENDED: "SUSPENDED",
  ACTIVATE: "ACTIVATE",
  DEFAULT: "DEFAULT",
};

//possible colors of mandate status
export const STATUS_COLOR_CODES = {
  ACCEPTED: "#007C92",
  [STATUS_DISPLAY_TEXTS.PENDING]: "#EB9700",
  [STATUS_DISPLAY_TEXTS.DEFAULT]: "#979797",
  [STATUS_DISPLAY_TEXTS.ACTIVATE]: "#BD0000",
};

export const TIMESTAMP_REGEX = /,[\s]*/;
