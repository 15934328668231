import { Environment } from "@vodacom/web-env";

Environment.init({
  Local: {
    uiHost: "http://localhost:3000",
    apiHost: "http://localhost:8080",
    name: "Localhost",
    uiBase: "/cloud/dls/msl/online",
    apiBase: "/msl",
  },
  Dev: {
    uiHost: "https://wwwdev.vodacom.co.za",
    apiHost: "https://vodapayapidev.vodacom.co.za",
    name: "Development",
    uiBase: "/cloud/dls/msl/online",
    apiBase: "/dlsapi/online/msl",
  },
  QA: {
    uiHost: "https://wwwqa.vodacom.co.za",
    apiHost: "https://vodapayapiqa.vodacom.co.za",
    name: "Quality Assurance",
    uiBase: "/cloud/dls/msl/online",
    apiBase: "/dlsapi/online/msl",
  },
  Staging: {
    uiHost: "https://wwwstg.vodacom.co.za",
    apiHost: "https://vodapayapistg.vodacom.co.za",
    name: "Staging",
    uiBase: "/cloud/dls/msl/online",
    apiBase: "/dlsapi/online/msl",
  },
  Prod: {
    uiHost: "https://www.vodacom.co.za",
    apiHost: "https://vodapayapi.vodacom.co.za",
    name: "Production",
    uiBase: "/cloud/dls/msl/online",
    apiBase: "/dlsapi/online/msl",
  },
});
