import {
  DEFAULT_TECHNICAL_ERROR_MESSAGE,
  DEFAULT_TECHNICAL_ERROR_TITLE,
  TIMEOUT_MESSAGE,
  TIMEOUT_SECOND_MESSAGE,
  TIMEOUT_TITLE,
} from "utils/constants";

import ErrorIcon from "images/error.svg";
export class AppUtilsProvider {
  static reloadApplication = () => {
    window.location.reload();
  };

  static handleCloseATB = () => {
    window.location.href = `http://bit.ly/VodacomATB`;
  };
  static handleCloseHome = () => {
    window.location.href = `https://help.vodacom.co.za/personal/home`;
  };
  static handleManageServices = () => {
    window.location.href = `https://bit.ly/3if1lzJ`;
  };
  static handlePartnerRedirectUrl = (url) => {
    window.location.href = url;
  };

  static showTimeoutScreen = (data, actions) => {
    actions.updateDefaultError({
      display: true,
      title: TIMEOUT_TITLE,
      hideIcon: true,
      iconUrl: ErrorIcon,
      messages: TIMEOUT_MESSAGE,
      second_messages: TIMEOUT_SECOND_MESSAGE,
      primaryButtonText: "Try again",
      primaryButtonClickHandler: () =>
        this.handlePartnerRedirectUrl(data.redirectPartnerUrl),
      secondaryButtonText: "Close",
      secondaryButtonClickHandler: this.handleCloseATB,
    });
  };

  static showDefaultErrorScreen = (actions) => {
    actions.updateDefaultError({
      display: true,
      title: DEFAULT_TECHNICAL_ERROR_TITLE,
      messages: DEFAULT_TECHNICAL_ERROR_MESSAGE,
      primaryButtonText: "Close",
      iconUrl: ErrorIcon,
      primaryButtonClickHandler: this.handleCloseATB,
    });
  };

  static iterateBoldMessage(msg) {
    const cardComponentList = [];
    let newstr = msg.replace(/(<([^>]+)>)/gi, "&bold");
    let strarray = newstr.split("&bold");
    let cardComponentItem = "";

    for (let index in strarray) {
      if (index % 2 === 0) {
        cardComponentItem = <span key={index}>{strarray[index]}</span>;
      } else {
        cardComponentItem = (
          <span key={index} className="boldfont">
            {strarray[index]}
          </span>
        );
      }

      cardComponentList.push(cardComponentItem);
    }
    return cardComponentList;
  }
}
