import React, { lazy } from "react";

// Lazily load the components
const BannerImage = lazy(() => import("ui/common-components/banner-image"));
const MsisdnScreen = lazy(() => import("ui/common-components/msisdn-screen"));
const AppSpinner = lazy(() => import("ui/common-components/app-spinner"));

export const MainPage = (props) => {
  const { data, actions } = props;

  return (
    <>
      
        {data.bannerFlag && <BannerImage   data={data}
          actions={actions} />}
        {data.spinnerFlag && <AppSpinner/>}
        <MsisdnScreen
          data={data}
          actions={actions}
          productCatalog={data.productCatalog}
          defaultError={data.defaultError}
        />

    </>
  );
};