export const getTxnid = () => {
  const currentUrlPath = window.location.href;
  const txnidIndex = currentUrlPath.indexOf("?transaction-id=");
  if (txnidIndex !== -1) {
    const txnid = currentUrlPath.slice(
      txnidIndex + 16,
      currentUrlPath.length
    );
    return txnid
  }
  return null;
};